import { Box, Typography } from "@material-ui/core";

function index() {  
    return (
        <Box>
            <Typography variant="h2">Bem vindo ao</Typography>
            <Typography variant="h1">Águia App</Typography>
        </Box>
    );
}

export default index;
